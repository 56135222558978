export const environment = {
    production: false,
    accountName : "sdpddp", // sdpddp sdpsdsprsa
    containerName: "workspace", // workspace test
    // container's sasToken
    key: "sp=rwdl&st=2022-03-07T14:54:47Z&se=2023-03-07T22:54:47Z&spr=https&sv=2020-08-04&sr=c&sig=h9DUZlPLVVDdc7Z8AOmEjNwcgQc6sFHFSXv1BKSrFTM%3D", //sp=r&st=2022-03-07T14:54:47Z&se=2023-03-07T22:54:47Z&spr=https&sv=2020-08-04&sr=c&sig=CsTOJ55UFgdhB6YCAMURW00kZxYeXbYfayyS2T%2Bg5IY%3D "sp=racwl&st=2022-02-03T14:28:27Z&se=2022-02-28T22:28:27Z&spr=https&sv=2020-08-04&sr=c&sig=fFBLMvJCY0DsxvVpi%2FDAuTS%2B5mOBsx9JN%2FZYeCFIhyE%3D",
    //key: "sp=racwdl&st=2022-03-02T13:24:11Z&se=2022-03-07T21:24:11Z&spr=https&sv=2020-08-04&sr=c&sig=rFX4q9VgJXieIqs%2BTe4QpO6Bvc4UwaVYFN4QAij1mmc%3D",

    tenantId: '67416604-6509-4014-9859-45e709f53d3f', // tenant id of your Azure App Registration
    clientId: '126008cb-6d9b-4c8e-b97a-9e19227f47e9', // client id of your Azure App Registration
    appId: 'api://126008cb-6d9b-4c8e-b97a-9e19227f47e9/user_impersonation', // scope that is desired in your claim

    userRole: 'bc3cdb07-16f4-436a-b1fc-aec87e3c69c6',
    adminRole: '1955a813-5623-4312-b6f5-8f1f843f0406',

    //appScope: 'api://XXX/YYY',
    
    azureBackendAPI: 'https://worksite.onmicrosoft.com/SG_sdp-s-spr-frontend_D/user_impersonation',
};
