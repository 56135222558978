<div class="navbarContainer flex flex-row justify-center items-center">
    <div class="flex flex-col">
      <span class="pl-2"> Schaeffler Group USA</span>
      <span class="pl-2"> <strong>Spare Parts Recognition</strong></span>
    </div>

    <img
        src="data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='199.1' height='22.6'%3E%3Cg fill='%2300893d'%3E%3Cpath d='M13.6 10.9a9.15 9.15 0 0 0-4-1.8C8.2 8.7 6.8 8.4 5.8 8c-1.1-.6-1.7-1.1-1.7-2 0-1.7 1.1-2.5 2.7-2.5a4.06 4.06 0 0 1 2.7 1 4.6 4.6 0 0 1 1.3 2.7h3.4V.6h-3.5v1.1a7.11 7.11 0 0 0-4-1.6 6.27 6.27 0 0 0-5.15 2.3A6.62 6.62 0 0 0 .1 6.2a6.63 6.63 0 0 0 2.5 5.4 15.22 15.22 0 0 0 3.3 1.3 23.17 23.17 0 0 1 4 1 2.35 2.35 0 0 1 1.4 2.4 2.86 2.86 0 0 1-2.75 2.8c-2.7 0-4.4-1.8-4.8-4.5H0v7.5h3.55v-1.3a7.88 7.88 0 0 0 4.9 1.7 7.17 7.17 0 0 0 4.9-1.8 6.48 6.48 0 0 0 2-4.9c.1-2.5-.6-3.9-1.7-4.9M34.2 14.5c-1.3 2.4-2.7 4-5.8 4-4.2 0-6.5-3.3-6.6-7.2s2.4-7.2 6.6-7.2a5.8 5.8 0 0 1 3.3 1 4.54 4.54 0 0 1 2 2.7v.1h3.5V.6h-3.5v1.1A9.87 9.87 0 0 0 28.55 0a10.87 10.87 0 0 0-7.3 2.7c-2.35 1.7-3.7 4.5-3.7 8.4a11.58 11.58 0 0 0 3.1 8.2 10.56 10.56 0 0 0 7.8 3.3c4.7 0 8.3-2.5 9.6-7.6v-.3a24.2 24.2 0 0 0-3.8-.2M161.4.3v3.4h2.1v15h-2.1v3.4h16.3v-6.9h-3.6v3.5h-6.3v-6.2h5.8V8.8h-5.8V3.7h6.3v2.6h3.6v-6h-16.3zM155.8 15.3v3.4h-4.6v-15h2.3V.3h-8.9v3.4h2.4v15h-2.4v3.4h14.7v-6.8h-3.5zM187.4 3.7h1.7a9.88 9.88 0 0 1 3 .3 1.91 1.91 0 0 1 1.45 2c0 1.4-.4 2-1.1 2.4a5.67 5.67 0 0 1-2.7.6h-2.1l-.2-5.3zm9.8 15v-4.5a4 4 0 0 0-.4-2.1 4 4 0 0 0-2-1.4 5.21 5.21 0 0 0 3.1-4.8v-.1a4.78 4.78 0 0 0-1-3C195.2.4 192.7.3 191 .3h-9.9v3.4h2.3v14.8h-2.3v3.4h8.5v-3.1h-2.05v-6.2h2a5.83 5.83 0 0 1 2.3.3 1.54 1.54 0 0 1 1.1 1.6v7.6h6.2v-3.4zM59.8 3.7V.3h-8.4v3.4h1.9v5.1h-6.9V3.7h1.9V.3H40v3.4h2.1v15H40v3.4h8.3v-3.4h-1.9v-6.2h6.9v6.2h-1.9v3.4h8.4v-3.4h-2.1v-15h2.1zM124.8.3v3.4h2.3v15h-2.3v3.4h8.8v-3.4h-2.3v-6.2h6.2V8.8h-6.2V3.7h6.5v2.6h3.5v-6h-16.5zM70.4 11.6l2.3-7.2h.1l2.1 7.2zm10.7 7.1l-4.9-15h2.1V.3H67v3.4h2.1L64 18.5h-1.45v3.4h7.5v-3.4h-2l1.1-3.7h6.2l1.1 3.7h-1.7v3.4h7.8v-3.4c0 .2-1.45.2-1.45.2zM85.6.3v3.4h2.2v15h-2.2v3.4h16.3v-6.9h-3.5v3.5H92v-6.2h5.8V8.8H92V3.7h6.4v2.6h3.5v-6H85.6zM105.7.3v3.4h2.3v15h-2.3v3.4h8.8V18.7h-2.3v-6.2h6.4V8.8h-6.4V3.7h6.5v2.6h3.6v-6h-16.6z'/%3E%3C/g%3E%3C/svg%3E"
        alt="Logo Schaeffler"
        onerror="this.src='assets/logo_SCHAEFFLER_office_vert.png';"
        class="rightLogo w-48 sm:w-52"
        (click)="redirectToHomePage()"
    >
</div>
