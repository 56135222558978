import { HttpClient, HttpErrorResponse, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, throwError } from 'rxjs';
import { retry, catchError } from 'rxjs/operators';
import { Image } from 'src/app/shared/models/image.model';
import { Prediction } from 'src/app/shared/models/prediction.model';

@Injectable({
    providedIn: 'root'
})
export class PartRecognitionService {

    constructor(private http: HttpClient) { }

    private readonly apiStatusURL: string = "/api/v1/public/health";
    private readonly inferImageURL: string = "/api/v1/public/predict";
    private readonly inferFileURL: string = "/api/v1/predict";
    private readonly apiAddPartURL: string = "/api/v1/add";

    private readonly head = new HttpHeaders()
            .append('Accept', '*/*')
            .append('Content-Type', 'application/json');

    private readonly uploadHeader = new HttpHeaders()
      .append('Accept', '*/*');


    getApiStatus(): Observable<String> {
        return this.http.get<String>(this.apiStatusURL)
        .pipe(
            retry(2),
            catchError(this.handleError)
        )
    }

    postImageToInfer(postImage: Image): Observable<Prediction> {
        return this.http.post<Prediction>(this.inferImageURL, postImage, {headers: this.head})
        .pipe(
            retry(2),
            catchError(this.handleError)
        );
    }

    postFileToInfer(postFile: FormData): Observable<Prediction[]> {
        //return this.http.post<Prediction>(`${this.inferFileURL}?image_input=${postFile}`, null)
        return this.http.post<Prediction[]>(this.inferFileURL, postFile)
        .pipe(
            retry(2),
            catchError(this.handleError)
        );
    }

    handleError(error: HttpErrorResponse) {
        let errorMessage = '';
        if (error.error instanceof ErrorEvent) {
            // client side error
            errorMessage = error.error.message;
        } else {
            // server side error
            errorMessage = `Error code: ${error.status}, ` + `message: ${error.message}`;
        }
        console.log(errorMessage);
        return throwError(errorMessage);
    };

    postNewPartImage(newMaterialInfo: FormData): Observable<string> {
      return this.http.post<string>(this.apiAddPartURL, newMaterialInfo, {headers: this.uploadHeader})
        .pipe(
          retry(2),
          catchError(this.handleError)
      );
    }
}
