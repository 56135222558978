import { HttpClient, HttpErrorResponse, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, throwError } from 'rxjs';
import { retry, catchError } from 'rxjs/operators';
import { environment } from 'src/environments/environment';

@Injectable({
    providedIn: 'root'
})
export class TokenConfigService {

    constructor(private http: HttpClient) { }

    private readonly head = new HttpHeaders()
        .append('Accept', '*/*')
        .append('Content-Type', 'application/json');

    postUserToGetRole(): Observable<any> {
        const appRoleAssignment = {
            principalId: environment.tenantId,
            resourceId: environment.clientId,
            appRoleId: environment.userRole
        };

        return this.http.post<any>(`https://graph.microsoft.com/v1.0/users/${environment.tenantId}/appRoleAssignments`, appRoleAssignment)
            .pipe(
                retry(2),
                catchError(this.handleError)
            );
    }

    handleError(error: HttpErrorResponse) {
        let errorMessage = '';
        if (error.error instanceof ErrorEvent) {
            // client side error
            errorMessage = error.error.message;
        } else {
            // server side error
            errorMessage = `Error code: ${error.status}, ` + `message: ${error.message}`;
        }
        console.log(errorMessage);
        return throwError(errorMessage);
    };
}
