import { Injectable } from '@angular/core';
import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Observable } from 'rxjs';
import { OAuthService } from 'angular-oauth2-oidc';

@Injectable()
export class AuthInterceptor implements HttpInterceptor {

  constructor(private readonly authService: OAuthService) { }

  public intercept(
      request: HttpRequest<any>,
      next: HttpHandler
  ): Observable<HttpEvent<any>> {
      //const token = (<any>this).authService.accessToken;
      const token = this.authService.getAccessToken();

      if (!token) {
          // error handling logic
          return next.handle(request);
      }

      if (request.url.indexOf('ccapi/ver100') >= 0) {
        const cameraRequest = request.clone({
          headers: request.headers
                //.set('Access-Control-Allow-Origin', 'http://192.168.1.2:8080/ccapi/*')
                .set('Accept', '*/*')
        });

        return next.handle(cameraRequest);
      }

      const claims : any = this.authService.getIdentityClaims();

      const authorizedRequest = request.clone({
          headers: request.headers
                .set('Authorization', `Bearer ${token}`)
                .set('From', claims.email)
                //.set('Access-Control-Allow-Origin', '*')
                // .set('Accept', '*/*')
                // .set('Content-Type', 'application/json')
      });




      return next.handle(authorizedRequest);
  }

}
