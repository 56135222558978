import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';

import { OAuthService } from 'angular-oauth2-oidc';
import { PartRecognitionService } from './core/services/part-recognition.service';
import { TokenConfigService } from './core/services/token-config.service';

@Component({
    selector: 'app-root',
    templateUrl: './app.component.html',
    styleUrls: ['./app.component.css']
})
export class AppComponent implements OnInit {
    title = 'spare-parts-id';
    jwt: String = '';

    constructor(
      private router: Router,
      private oauthService: OAuthService,
      private http: HttpClient,
      private partRecognitionService: PartRecognitionService,
      private tokenConfigService: TokenConfigService) {
        this.configure();
    }

    ngOnInit(): void {
        this.getDevices();
        if (!localStorage['nonce'])
          this.login();
    }

    private configure() {
        this.oauthService.loadDiscoveryDocumentAndTryLogin({
          customHashFragment: location.hash,
          onTokenReceived: (info) => {
            if (info.state) {
              this.router.navigate([info.state]);
            }
          }
        })
        .then(doc => {
          if(!this.oauthService.hasValidAccessToken()) {
            console.log('invalid token found');
            this.login();
            console.log('token refreshed');
          }
          this.assignToken();
          this.oauthService.setupAutomaticSilentRefresh();
          //const claims = this.oauthService.getIdentityClaims() as any;
          //const name = this.getProperty(claims, "name");
          this.router.initialNavigation();
        })
        .catch(error => {
          console.log('error found:' + error);
        });/*
            .then(doc => {
                console.log('doc');
                this.oauthService.tryLogin();
            });*/
    }

    getProperty<T, K extends keyof T>(obj: T, key: K) {
      return obj[key]; // Inferred type is T[K]
    }

    assignToken() {
        this.jwt = this.oauthService.getAccessToken();
    }

    getDevices() {
      navigator.mediaDevices.enumerateDevices()
      .then(function(devices) {
        devices.forEach(function(device) {
          console.log(device.kind + ": " + device.label +
                      " id = " + device.deviceId);
        });
      })
      .catch(function(err) {
        console.log(err.name + ": " + err.message);
      });
    }

    initCamera(config:any) {
        var browser = <any>navigator;

        browser.getUserMedia = (browser.getUserMedia ||
          browser.webkitGetUserMedia ||
          browser.mozGetUserMedia ||
          browser.msGetUserMedia);

        browser.mediaDevices.getUserMedia(config).then((data: any) => {
            console.log(data)

        });
    }

    login(): void {
        //console.log(window.location.origin);
        this.oauthService.initCodeFlow();
    }

    logout() {
        this.oauthService.logOut();
    }

    get givenName() {
        const claims = this.oauthService.getIdentityClaims();
        console.log(claims);
        if (!claims) {
            console.log(claims);
            return null;
        }

        console.log(claims);

        return claims;
    }
}
