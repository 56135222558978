import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { HomeComponent } from './home/home.component';
// import { PagenotFoundComponent} from './shared/pagenotfound/pagenotfound.component'

//import { MsalGuard } from '@azure/msal-angular';

const routes: Routes = [
    {
      path: 'add-part',
      loadChildren: () => import('./core/components/add-part/add-part.module').then(m => m.AddPartModule)
    },
    {
      path: 'find-part',
      loadChildren: () => import('./core/components/find-part/find-part.module').then(m => m.FindPartModule)
    },
    { path: '', redirectTo: 'home-page', pathMatch: 'full' },
    { path: 'home-page', component: HomeComponent },
    // { path: '**', component: PagenotFoundComponent }
];

@NgModule({
    imports: [RouterModule.forRoot(routes, { useHash: true, initialNavigation: 'disabled' })],
    exports: [RouterModule]
})
export class AppRoutingModule { }
