import { AuthConfig } from "angular-oauth2-oidc";
import { environment } from "src/environments/environment";

export const authConfig: AuthConfig = {
    issuer: `https://login.microsoftonline.com/${environment.tenantId}/v2.0`, // Tenant ID that your registered app in Azure belongs to
    tokenEndpoint: `https://login.microsoftonline.com/${environment.tenantId}/oauth2/v2.0/token`, // Tenant ID that your registered app in Azure belongs to
    loginUrl: `https://login.microsoftonline.com/${environment.tenantId}/oauth2/v2.0/authorize`, // Tenant ID that your registered app in Azure belongs to
    logoutUrl: `https://login.microsoftonline.com/${environment.tenantId}/oauth2/v2.0/logout`, // Tenant ID that your registered app in Azure belongs to
    redirectUri: window.location.origin,
    postLogoutRedirectUri: origin,
    useSilentRefresh: false,
    clientId: `${environment.clientId}`, // the ID of your registered Azure app
    scope: `openid profile email offline_access ${environment.appId}`, // The backend app API that you should have been added to the API permissions of your frontend app in the Azure Portal (cf. first point of Prerequisites above)
    showDebugInformation: true,
    strictDiscoveryDocumentValidation: false,
    oidc: true,
    skipIssuerCheck: true,
    responseType: 'code',
    clearHashAfterLogin: false,
    disableAtHashCheck: true
};
